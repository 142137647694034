.textFieldContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: start;
}

.lable {
    color: black;
}

.textField {
    background-color: white;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 10px;
    width: 90%;
    margin:5px 10px 10px 0px;
}

.shack {
    animation: shack .3s infinite;
    
}

@keyframes shack {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

input[type="password"] {
    color: black;
}

::placeholder {
    color: black;
}