body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Crimson Pro", serif, "Montserrat", sans-serif;
}
